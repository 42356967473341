exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/BlogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2017-index-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/vercel/path0/content/blog/2017/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2017-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-douban-pulse-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/vercel/path0/content/blog/douban-pulse/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-douban-pulse-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-farewell-to-toyojazz-index-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/vercel/path0/content/blog/farewell-to-toyojazz/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-farewell-to-toyojazz-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-japanese-self-study-resources-index-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/vercel/path0/content/blog/japanese-self-study-resources/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-japanese-self-study-resources-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-playseat-sensation-pro-first-unboxing-setup-and-review-in-china-index-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/vercel/path0/content/blog/playseat-sensation-pro-first-unboxing-setup-and-review-in-china/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-playseat-sensation-pro-first-unboxing-setup-and-review-in-china-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-talk-about-the-apple-car-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/vercel/path0/content/blog/talk-about-the-apple-car/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-talk-about-the-apple-car-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-the-best-ps-4-racing-simulators-for-2020-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/vercel/path0/content/blog/the-best-PS4-racing-simulators-for-2020/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-the-best-ps-4-racing-simulators-for-2020-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-the-exact-location-of-beijing-tianquan-temple-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/vercel/path0/content/blog/the-exact-location-of-beijing-tianquan-temple/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-the-exact-location-of-beijing-tianquan-temple-index-mdx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/Index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */)
}

